<template>
  <v-card flat>
    <v-toolbar color="teal" dark>
      <v-toolbar-title>Novo Período</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn icon v-on:click="$emit('close-dialog')"
        ><v-icon arge>mdi-close-circle</v-icon></v-btn
      >
    </v-toolbar>

    <v-card-text>
      <v-form ref="form" v-model="valid">
        <v-row class="mb-2">
          <v-col>
            <v-datetime-picker
              label="Data de início"
              placeholder="Escolha a data de início"
              clearText="Limpar"
              okText="Salvar"
              v-model="period.data_inicio"
            >
            </v-datetime-picker>
          </v-col>
          <v-col>
            <v-datetime-picker
              label="Data de termino"
              placeholder="Escolha a data de termino"
              clearText="Limpar"
              okText="Salvar"
              v-model="period.data_fim"
            >
            </v-datetime-picker>
          </v-col>
        </v-row>
      </v-form>
    </v-card-text>
    <v-card-actions class="">
      <v-spacer></v-spacer>

      <v-btn
        color="success"
        outlined
        large
        class="btn-block"
        :disabled="!valid"
        @click="creatPeriod()"
        >Cadastrar Período</v-btn
      >
    </v-card-actions>
  </v-card>
</template>

<script>
import { formatDate } from "../../mixins";
export default {
  mixins: [formatDate],
  data: () => {
    return {
      valid: true,
      period: {
        data_inicio: null,
        data_fim: null
      }
    };
  },

  methods: {
    async creatPeriod() {
      try {
        const result = await this.$http.post("/saleperiod", this.period);
        if (result.status == 201) {
          this.$toast.success("Período Cadastrado com Sucesso!");
          this.$emit("update-list");
        }
      } catch (error) {
        if (error.response.status == 400) {
          this.$toast.error("Existe um período em andamento!");
          this.$emit("update-list");
        } else {
          this.$toast.error("Falha ao Cadastrar novo Período!");
        }
      }
    }
  }
};
</script>

<style>
.v-slide-group__wrapper {
  display: none;
}
.v-item-group theme--light v-slide-group v-tabs-bar primary--text {
  display: none;
}
</style>