<template>
  <v-container>
    <v-row class="justify-center">
      <v-data-table
        v-if="periods"
        :headers="header"
        :items="periods"
        item-key="id"
        class="elevation-1"
      >
        <template v-slot:top>
          <v-toolbar dense flat class="teal" dark>
            <v-toolbar-title>Períodos</v-toolbar-title>

            <v-spacer></v-spacer>
            <v-tooltip right>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  color=""
                  v-on="on"
                  v-bind="attrs"
                  class="mt-2 mb-2"
                  icon
                  x-large
                  @click="dialogNewPeriod = true"
                >
                  <v-icon>mdi-plus-circle</v-icon>
                </v-btn>
              </template>
              Cadastrar novo Período
            </v-tooltip>
          </v-toolbar>
          <v-divider></v-divider>
        </template>
        <template v-slot:item.data_inicio="{ item }">
          {{ item.data_inicio | formatDate }}
        </template>
        <template v-slot:item.data_fim="{ item }">
          {{ item.data_fim | formatDate }}
        </template>
        <template v-slot:item.actions="{ item }">
          <v-tooltip right>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                text
                icon
                v-bind="attrs"
                v-on="on"
                color="red lighten-2"
                @click="closePeriod(item)"
              >
                <v-icon>
                  mdi-calendar-remove-outline
                </v-icon></v-btn
              >
            </template>
            Encerrar Período
          </v-tooltip>
        </template>
      </v-data-table>
    </v-row>
    <v-dialog
      class="justify-center"
      v-model="dialogNewPeriod"
      overlay-opacity="0.3"
      max-width="500px"
      transition="dialog-transition"
    >
      <newPeriod
        v-on:close-dialog="closeNewPeriod"
        v-on:update-list="refreshList"
        v-if="dialogNewPeriod"
      />
    </v-dialog>
  </v-container>
</template>

<script>
import { formatDate } from "../../mixins";
import newPeriod from "./newPeriod";
export default {
  components: {
    newPeriod
  },
  mixins: [formatDate],
  data: () => {
    return {
      hello: "Hello my baby!",
      periods: [],

      header: [
        { text: "ID", value: "id" },
        { text: "Período", value: "periodo" },
        { text: "Início", value: "data_inicio" },
        { text: "Fim", value: "data_fim" },
        { text: "Status", value: "status" },
        { text: "Ações", value: "actions" }
      ],
      dialogNewPeriod: false
    };
  },
  created() {
    this.getPeriods();
  },
  methods: {
    async getPeriods() {
      try {
        const { data } = await this.$http.get("/saleperiod");
        this.periods = data;
      } catch (error) {
        this.$toast.error("Não foi possivel carregar os períodos.");
      }
    },
    closeNewPeriod() {
      this.dialogNewPeriod = false;
    },
    async closePeriod(period) {
      const result = await this.$http.post(
        `/saleperiod/${period.id}/close`,
        period
      );
      if (result.status == 201) {
        this.$toast.success("Período encerrado com Sucesso!");
       this.refreshList()
      } else {
        this.$toast.error("Falha ao encerrado Período!");
      }
    },
    refreshList() {
      this.dialogNewPeriod = false;
      this.getPeriods();
    }
  }
};
</script>

<style>
</style>