var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('v-row',{staticClass:"justify-center"},[(_vm.periods)?_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.header,"items":_vm.periods,"item-key":"id"},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{staticClass:"teal",attrs:{"dense":"","flat":"","dark":""}},[_c('v-toolbar-title',[_vm._v("Períodos")]),_c('v-spacer'),_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mt-2 mb-2",attrs:{"color":"","icon":"","x-large":""},on:{"click":function($event){_vm.dialogNewPeriod = true}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-plus-circle")])],1)]}}],null,false,2246550257)},[_vm._v(" Cadastrar novo Período ")])],1),_c('v-divider')]},proxy:true},{key:"item.data_inicio",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("formatDate")(item.data_inicio))+" ")]}},{key:"item.data_fim",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("formatDate")(item.data_fim))+" ")]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"text":"","icon":"","color":"red lighten-2"},on:{"click":function($event){return _vm.closePeriod(item)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(" mdi-calendar-remove-outline ")])],1)]}}],null,true)},[_vm._v(" Encerrar Período ")])]}}],null,false,755845345)}):_vm._e()],1),_c('v-dialog',{staticClass:"justify-center",attrs:{"overlay-opacity":"0.3","max-width":"500px","transition":"dialog-transition"},model:{value:(_vm.dialogNewPeriod),callback:function ($$v) {_vm.dialogNewPeriod=$$v},expression:"dialogNewPeriod"}},[(_vm.dialogNewPeriod)?_c('newPeriod',{on:{"close-dialog":_vm.closeNewPeriod,"update-list":_vm.refreshList}}):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }